.section {
    font-weight: 500;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 72px 60px 20px 100px;
    overflow: hidden;
}

.bloc {
    height: min-content;
    padding: 40px;
    background-color: rgb(248, 244, 234);
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.471);
    opacity: 0;
    transform: translateY(500px);
}

.bloc[animation='2'] {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.6s 0.8s ease;
}

.deco {
    position: absolute;
    top: 0;
    left: 48px;
    width: 4px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transform: rotate(90deg);
}

.deco[animation='2'] {
    opacity: 1;
    transform: rotate(0);
    transition: all 0.6s 0.6s ease;
}

.square {
    position: absolute;
    top: calc(50% - 10px);
    left: -8px;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(220, 179, 83);
    transform: rotate(45deg);
    animation: squareAnimation 2s 1s ease-in-out alternate-reverse infinite backwards;
}

.parallax {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Hauteur d'écran petite */
@media (max-height: 500px) {
    .section {
        padding: 20px 60px 20px 100px;
    }
}

/* Mode tablette */
@media (max-width: 992px) {
    .section {
        font-size: 0.9em;
        padding: 59px 34px 20px 66px;
    }

    .deco {
        left: 31px;
        width: 3px;
    }
    
    .bloc {
        padding: 30px;
    }

    .square {
        width: 14px;
        height: 14px;
        left: -5px;
    }

    /* Hauteur d'écran petite */
    @media (max-height: 500px) {
        .section {
            padding: 20px 34px 20px 66px;
        }
    }
}

/* Mode mobile */
@media (max-width: 576px) {
    .section {
        padding: 53px 0 10px 0;
    }

    .deco {
        display: none;
    }

    .bloc {
        padding: 20px 10px;
        background-color: transparent;
        box-shadow: none;
    }

    .bloc[animation='2'] {
        transition-delay: 0.5s;
    }

    /* Hauteur d'écran petite */
    @media (max-height: 500px) {
        .section {
            padding: 10px 0;
        }
    }
}

/* Animation du losange */
@keyframes squareAnimation {
    0% {
        background-color: rgb(220, 178, 83);
    }
    100% {
        transform: scale(1.3) rotate(45deg);
        background-color: rgb(238, 227, 196);
    }
}