/* Balises générales */
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: black;
}

button {
  appearance: none;
  color: black;
}

strong {
  font-weight: 700;
  font-style: italic;
}

body {
  margin: auto;
  font-weight: 400;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

p {
  margin: 15px;
}

h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.3em;
  background-color: white;
  padding: 15px;
}

em {
  font-style: italic;
  font-weight: 600;
  font-size: 1.1em;
  text-decoration: underline;
}

/* Style de la page (le footer reste toujours en bas de fenêtre) */
#root {
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(45deg, rgb(241, 235, 220), rgb(238, 227, 196), rgb(241, 235, 220), rgb(238, 227, 196), rgb(241, 235, 220));
  margin: auto;
  background-size: cover;
}

/* Pour l'accessibilité */
.visually-hidden {
position: absolute;
width: 0;
height: 0;
padding: 0;
margin: -1px;
overflow: hidden;
white-space: nowrap;
border: 0;
}

/* Modale */
.ReactModal__Overlay {
  z-index: 20;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: scale(0);
  transition: transform 500ms ease-in-out;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  transform: scale(1);
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  transform: scale(0);
}