/* Listes */
.list li {
    margin-left: 40px;
    list-style-type: disc;
    margin-top: 10px;
}

.list {
    margin: 15px;
}

/* Liens et icônes */
link, .icone {
    opacity: 1;
    transition: opacity 100ms;
}

.link {
    font-style: italic;
    border-bottom: 1px solid rgb(220, 171, 58);
}

.link:hover, link:focus, link:active, .icone:hover, .icone:focus, .icone:active {
    opacity: 0.7;
    transition: opacity 100ms;
}

/* Bouton d'ouverture du formulaire */
.buttonForm {
    margin-left: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    bottom: 2px;
    padding: 0;
    height: 20px;
    width: 20px;
}

/* Formulaire */
.form {
    display: flex;
    flex-direction: column;
    padding: 60px 40px;
    gap: 15px;
    width: 100%;
}

.sectionForm {
    width: 100%;
    height: 100%;
    margin: auto;
}

.input {
    font-family: 'Open Sans', sans-serif;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.401);
    border-left: 5px solid rgb(218, 189, 124);
}

.input:focus {
    background-color: rgb(244, 241, 234);
    outline: none;
}

.buttonSubmit {
    appearance: none;
    width: min-content;
    border-radius: 0;
    color: black;
    padding: 5px 15px;
    margin: 15px auto 0 auto;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    box-shadow: none;
    transition: all 200ms;
    border: 1px solid rgba(0, 0, 0, 0.8);
    background-color: rgb(220, 178, 83);
    font-weight: 500;
}

.buttonSubmit[disabled] {
        cursor: auto;
        background-color: rgb(240, 233, 216);
}

.buttonSubmit:hover, .buttonSubmit:focus, .buttonSubmit:active {
    background-color: white;
    transition: background-color 200ms;
}

.buttonSubmit[disabled]:hover, .buttonSubmit[disabled]:focus, .buttonSubmit[disabled]:active {
    cursor: auto;
    background-color: rgb(240, 233, 216);
}

.form textarea {
    height: 20vh;
    min-height: 100px;
    font-family: 'Open Sans', sans-serif;
    padding: 10px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.401);
    border-left: 5px solid rgb(218, 189, 124);
}

@-moz-document url-prefix() {
    .form textarea::placeholder {
        font-size: 0.8em;
    }
}

.form textarea:focus {
    background-color: rgb(244, 241, 234);
    outline: none;
}

/* Modale */
.modalContainer {
    height: 100%;
    position: relative;
}

.modal {
    background-color: rgb(246, 235, 214);
    width: 90%;
    max-width: 700px;
    max-height: 90vh;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.471);
    border-radius: 3px;
    overflow: auto;
    outline: 0;
}

/* Bouton de fermeture de la modale */
.buttonCloseForm {
    border: none;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 20px;
    width: 30px;
    height: 30px;
    color: black;
    background-color: transparent;
    padding: 0;
    transition: all 200ms;
}

.buttonCloseForm:hover, .buttonCloseForm:focus, .buttonCloseForm:active {
    color: rgb(220, 178, 83);
    transition: all 200ms;
}

@media (max-width: 576px) {
    .form {
        padding: 60px 10px 30px 10px;
        gap: 10px;
    }
}