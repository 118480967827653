.footer {
    background-color: rgb(233, 219, 182);
    padding: 10px 20px;
    border-top: 1px rgba(0, 0, 0, 0.2) solid;
    position: relative;
    z-index: 2;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
}

.footer > * {
    width: 30%;
}

.copyright {
    text-align: center;
    text-wrap: nowrap;
    margin: 0;
}

.copyright a {
    font-style: italic;
}

.copyright a:hover, .copyright a:focus {
    opacity: 0.8;
    transition: opacity 100ms;
}

.contact {
    text-align: right;
    font-size: 0.9em;
    text-wrap: nowrap;
}

.button {
    border: none;
    background-color: rgb(233, 219, 182);
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    font-size: 1em;
}

.button:hover, .button:focus, .button:active {
    opacity: 0.7;
    transition: opacity 100ms;
}

/* Icônes */
.icone {
    margin-right: 10px;
}

/* Modale */
.modalContainer {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.modal {
    background-color: rgb(246, 235, 214);
    width: 90%;
    max-width: 770px;
    max-height: 90vh;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.471);
    border-radius: 3px;
    overflow: auto;
    font-family: 'Open Sans', sans-serif;
    outline: 0;
}

.section {
    padding: 20px 40px;
    background-color: rgb(255, 252, 245);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.198);
}

.section li {
    margin-bottom: 10px;
}

.section span {
    font-weight: 600;
    margin-right: 10px;
    font-style: oblique;
}

.underline {
    text-decoration: underline;
    font-size: 1.1em;
    margin-left: 0;
    margin-bottom: 30px;
}

/* Bouton de fermeture de la modale */
.buttonCloseForm {
    border: none;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 12px;
    width: 30px;
    height: 30px;
    color: black;
    background-color: transparent;
    padding: 0;
    transition: all 200ms;
}

.buttonCloseForm:hover, .buttonCloseForm:focus, .buttonCloseForm:active {
    color: rgb(220, 178, 83);
    transition: all 200ms;
}

/* Mode tablette */
@media (max-width: 992px) {
    .footer > *:nth-child(1) {
        display: none;
    }

    .footer > *:nth-child(2) {
        width: 50%;
    }

    .footer > *:nth-child(3) {
        width: 40%;
    }

    .copyright {
        text-align: right;
    }

    .icone {
        margin-right: 5px;
    }

    .buttonCloseForm {
        right: 3px;
        top: 10px;
    }

    .section {
        font-size: 0.9em;
        padding: 10px 30px;
    }

    .modalContainer {
        padding: 33px;
    }
}

/* Mode mobile */
@media (max-width: 576px) {
    .footer {
        margin-top: 0;
        font-size: 0.8em;
        padding: 10px;
        flex-direction: column;
        gap: 7px;
    }

    .footer > *:nth-child(2), .footer > *:nth-child(3) {
        width: auto;
    }

    .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact li {
        width: min-content;
    }

    .section {
        padding: 0;
        box-shadow: none;
        background-color: rgb(246, 235, 214);
    }

    .modalContainer {
        padding: 10px 20px;
    }

    .buttonCloseForm {
        right: 1px;
        top: 5px;
    }
}