.header {
    background: linear-gradient(90deg, rgb(253, 248, 234), white);
    padding: 0 8px 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    width: 100%;
    z-index: 10;
    top: 0px;
}

/* Titre du site */
.h1 {
    font-family: 'Dancing Script', cursive;
    color: black;
    font-weight: 700;
    font-size: 2.1em;
    margin: 0 10px 0 10px;
}

.h1 .majuscule {
    color: rgb(220, 178, 83);
}

.h1 div[apparition='0'] {
    animation: tracking-out-contract 0.7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.h1 div[disparition='1'] {
    display: none;
}

.h1 div[apparition='1'] {
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.buttonLogo {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

/* Menu */
.ul {
    display: flex;
}

.li {
    padding: 20px 0;
    text-align: center;
    font-size: 1.1em;
    cursor: url('../../images/cursor/cat-solid.png') 0 0, pointer;
}

.li:hover, .li:active {
    border-bottom: 5px rgb(220, 178, 83) solid;
    padding-top: 20px;
    padding-bottom: 15px;
}

/* Bouton mode mobile pour ouvrir menu */
.iconeNav {
    display: none;
}

/* Liens de la navigation */
.a {
    padding: 20px;
    cursor: url('../../images/cursor/cat-solid.png') 0 0, pointer;
}

.active {
    font-weight: 700;
}

/* Mode tablette */
@media (max-width: 992px) {
    .header {
        padding: 3px 8px 8px 8px;
    }

    .h1 {
        font-size: 2.2em;
    }

    .li:focus-within a {
        border-bottom: 3px rgb(220, 178, 83) solid;
    }

    .nav {
        position: relative;
        padding: 1.5rem;
    }

    .iconeNav {
        display: block;
        cursor: pointer;
        border: none;
        background-color: transparent;
        position: absolute;
        right: 1.5rem;
        top: 49%;
        transform: translate(0, -50%);
        height: 32px;
        width: 34px;
    }

    .navMenu {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: black;
        transition: all 0.3s ease;
    }

    .iconeNav:hover .navMenuMiddle, .iconeNav:focus-within .navMenuMiddle {
        background-color: rgb(220, 178, 83);
        transition: background-color 50ms;
    }

    .navMenuTop {
        transform: translate(0, -10px);
    }

    .navMenuBottom {
        transform: translate(0, 10px);
    }

    .iconeNav[animation='1'] .navMenuTop {
        transform: translate(0, 0) rotate(45deg);
    }

    .iconeNav[animation='1'] .navMenuMiddle {
        opacity: 0;
    }

    .iconeNav[animation='1'] .navMenuBottom {
        transform: translate(0, 0) rotate(-45deg);
    }

    .hideMenu {
        display: none;
    }

    .containerNav {
        width: 100vw;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 8px);
        right: -8px;
        background-color: white;
    }

    .ul {
        flex-direction: column;
        justify-content: center;
        padding: 30px;
    }

    .ul[animation='0'] {
        animation: close 600ms 0ms both ease-in-out;
    }

    .ul[animation='1'] {
        animation: open 600ms 0ms both ease-in-out;
    }
    
    .li {
        font-size: 1em;
        padding: 0;
    }

    .a {
        display: inline-block;
        padding: 15px;
        width: 100%;
    }
    
    .li:hover, .li:focus-within, .li:active {
        background-color: rgb(220, 178, 83);
        border-bottom: none;
        padding: 0;  
    }
}

/* Mode mobile */
@media (max-width: 576px) {
    .header {
        padding: 0 0 5px 0;
    }
    
    .h1 {
        font-size: 1.7em;
    }

    .iconeNav {
        height: 20px;
        width: 28px;        
    }

    .navMenu {
        height: 2px;
    }

    .containerNav {
        top: calc(100% + 5px);
        right: 0;
    }
    
    .li {
        font-size: 0.9em;
    }

    .ul {
        padding: 30px 20px;
    }

    .a {
        padding: 20px;
    }
}

/* Ecrans très petits */
@media (max-width: 315px) {
    .h1 {
        font-size: 1.2em;
    }

    .iconeNav {
        width: 24px;        
    }

    .navMenuTop {
        transform: translate(0, -8px);
    }

    .navMenuBottom {
        transform: translate(0, 8px);
    }
}

/* Hauteur d'écran petite */
@media (max-height: 500px) {
    .header {
        position: relative;
    }
}

/* Animation du menu burger en mode mobile */

@keyframes open {
    0% {
        opacity: 0;
        max-height: 0;
        transform: translateY(-70px);
    }
    100% {
        opacity: 1;
        max-height: 400px;
        transform: translateY(0);
    }
}

@keyframes close {
    0% {
        opacity: 1;
        max-height: 400px;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        max-height: 0;
        transform: translateY(-70px);
    }
}

/* Animations du titre */
/* ----------------------------------------------
 * Generated by Animista on 2023-7-1 21:8:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
@keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
}
  